import './css/app.scss';
import React from 'react';

export default function PrivacyPolicy () {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>Last updated: March 15, 2023</p>
      <p>At SessionLink, we are committed to protecting your privacy and maintaining the security of any personal information we collect. This Privacy Policy outlines how we collect, use, and protect your data while using our SessionLink Chrome extension. By using our extension, you agree to the terms set forth in this Privacy Policy.</p>
      <h2>Information We Collect</h2>
      <p>While using SessionLink, we collect and process the following types of data:</p>
      <ol>
        <li>Site Data: We collect cookies, session storage, and local storage data from websites you use with SessionLink. This data is necessary for our extension to function and transfer your active sessions between browsers or computers.</li>
        <li>Encrypted Data: We store an encrypted version of your site data in the cloud, which can only be decrypted using a locally-stored encryption key.</li>
        <li>Encryption Key: The encryption key is generated and stored locally on your device, and it is not shared with us.</li>
      </ol>
      <h2>How We Use Your Information</h2>
      <p>We use the information we collect to:</p>
      <ol>
        <li>Facilitate the transfer of your active sessions between browsers or computers.</li>
        <li>Generate a shareable link for your encrypted data.</li>
        <li>Improve and enhance the functionality of our extension.</li>
      </ol>
      <h2>Data Storage, Retention, and Sharing</h2>
      <p>We implement industry-standard security measures to protect your data from unauthorized access, alteration, disclosure, or destruction. However, please be aware that no security measures can guarantee absolute protection, and we cannot ensure or warrant the security of any information you transmit to us.</p>
      <ol>
        <li>Storage: Your encrypted site data is stored in the cloud, while the encryption key remains stored locally on your device.</li>
        <li>Retention: We retain your encrypted site data for as long as it is necessary to fulfill the purposes described in this Privacy Policy, or until you request its deletion.</li>
        <li>Sharing: We do not share your personal information or site data with third parties, except as necessary for the extension to function. We may share your data with trusted service providers to facilitate the storage and retrieval of encrypted data, but they are required to protect your data in accordance with this Privacy Policy.</li>
      </ol>
      <h2>Your Rights</h2>
      <p>You have the right to request access to, modification of, or deletion of your personal information held by us. To exercise these rights, please contact us at [email address].</p>
      <h2>Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we encourage you to review it periodically. Your continued use of our extension following the posting of changes constitutes your acceptance of the updated Privacy Policy.</p>
      <h2>Contact Us</h2>
      <p>If you have any questions or concerns regarding this Privacy Policy, please contact us at: <a href='mailto:contact@sessionlink.app'>contact@sessionlink.app</a></p>
    </div>
  );
}
