import './css/app.scss';
import React, { useState, useEffect } from 'react';
import semverSatisfies from 'semver/functions/satisfies';
import PropTypes from 'prop-types';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { Route, Routes, useParams } from 'react-router-dom';
import axios from 'axios';
import { decompressFromUint8Array } from 'lz-string';
import { Link } from 'react-scroll';

import PrivacyPolicy from './privacy';
import logo from './img/key.svg';

/* global chrome */

library.add(faLink);
dom.watch();

const isProdSite = window.location.hostname === 'sessionlink.app';
const extensionId = isProdSite
  ? 'jhnpedhadifamilcjmmlmppciflckgkd' // store id
  : 'bacpoiidkebnmiknplmeplljnbfbbmfh'; // dev id

const minVersion = '>=0.1.0';

export default function App () {
  return (
    <div className="app">
      <Routes>
        <Route
          path="/link/:linkid"
          element={<SLLink />}
        />
        <Route
          path="/privacy"
          element={<PrivacyPolicy />}
        />
        <Route
          index
          element={<Landing />}
        />
      </Routes>
    </div>
  );
}

function decrypt (ciphertext, key, iv) {
  return new Promise((resolve) => {
    const crypto = window.crypto || window.msCrypto;
    const decrypted = crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv
      },
      key,
      ciphertext
    );
    decrypted.then((dec) => {
      resolve(new Uint8Array(dec));
    });
  });
}

function str2ab (str) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

function importKey (bKey) {
  const key = str2ab(atob(bKey));
  return window.crypto.subtle.importKey(
    'raw',
    key,
    {
      name: 'AES-GCM',
      length: 256
    },
    false,
    ['encrypt', 'decrypt']
  );
}

function SLLink () {
  const params = useParams();
  const [invalid, setInvalid] = useState(false);
  const [target, setTarget] = useState(null);
  const [sessionData, setSessionData] = useState({});

  useEffect(() => {
    axios.get(`https://api.sessionlink.app/link?id=${params.linkid}`)
      .then((response) => {
        if (!(response.data && response.data.result === 'success')) {
          setInvalid(true);
        } else {
          const data = response.data.data;
          const iv = response.data.iv;
          importKey(window.location.hash.substring(1))
            .then((key) => {
              decrypt(str2ab(atob(data)), key, str2ab(atob(iv)))
                .then((decrypted) => {
                  const decompressed = decompressFromUint8Array(decrypted);
                  const decoded = JSON.parse(decompressed);
                  decoded.id = params.linkid;
                  setSessionData(decoded);
                  setTarget(new URL(decoded.url));
                });
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [params.linkid]);

  const goToTarget = () => {
    chrome?.runtime?.sendMessage(extensionId, { decoded: sessionData },
      (res) => {
        if (res?.success) {
          window.location.href = target;
        } else {
          console.log('bad response');
          alert('Failed to communicate with SessionLink extension');
        }
      });
  };

  return (
    <AppContent>
      <div>
        {
          invalid
            ? <>
              <p>Oops! Looks like this link has already been used.</p>
              <Landing />
            </>
            : <>
              <button className="button" onClick={goToTarget}>Enter Session</button>
              {
                target
                  ? <p className='target-url'>{target.protocol}{`//`}<b>{target.host}</b>{target.pathname}{target.search}{target.hash}</p>
                  : <p>Loading...</p>
              }
            </>
        }
      </div>
    </AppContent>
  );
}

function Landing () {
  const [extVersion, setExtVersion] = useState(localStorage.getItem('extVersion') ? localStorage.getItem('extVersion') : null);

  useEffect(() => {
    chrome?.runtime?.sendMessage(extensionId, { version: true },
      (res) => {
        if (res?.version) {
          setExtVersion(res.version);
          localStorage.setItem('extVersion', res.version);
        }
      });
  }, []);

  return (
    <AppContent>
      <div className="section section1">
        <p>SessionLink is a simple extension that allows you to share your current session.</p>
        { extVersion
          ? semverSatisfies(extVersion, minVersion)
            ? <p className="text-orange"> Installed! </p>
            : <p> Installed. Update Required. </p>
          : <LinkToExtension />
        }
        <p className="scroll-link"><Link to="section2" smooth={true} offset={-112}><span></span>See how it works</Link></p>
      </div>
      <div className="section section2" id="section2">
        <p>
          SessionLink is a Chrome extension that enables users to transfer their active website sessions between browsers or computers.
          <br/><br/>
          It works by encrypting and uploading site data (such as cookies, session storage, and local storage) to the cloud, while keeping the encryption key locally.
          <br/><br/>
          A shareable link is generated, which can be used by another browser to access the session data. The encryption key is shared as part of the link (after the hash &apos;#&apos; - this part of the link is not sent by a browser when visiting it).
          <br/><br/>
          When a SessionLink URL is visited, the encrypted data is downloaded to the local browser and the key is used to decrypt it. Upon choosing to enter the session, the data is applied locally, and the user is seamlessly redirected to their original session.
        </p>
      </div>
    </AppContent>
  );
}

function LinkToTarget (props) {
  return (
    <div>
      <p>
        {props.target}
      </p>
      <button>
        {'Go ->'}
      </button>
    </div>
  );
}
LinkToTarget.propTypes = {
  target: PropTypes.string.isRequired
};

function LinkToExtension () {
  return (
    <a
      className="app-link"
      href={ isProdSite
        ? `https://chrome.google.com/webstore/detail/${extensionId}`
        : '/extension/SessionLinkChromeExtension.zip'
      }
      target="_blank"
      rel="noopener noreferrer"
    >
      Get SessionLink
    </a>
  );
}

function AppContent (props) {
  return (
    <>
      <div className="app-header">
        <img
          src={logo}
          alt="logo"
        />
        <h1>SessionLink</h1>
      </div>
      <div className="app-content">
        {props.children}
      </div>
      <div className="app-footer">
        <a href="/privacy">
          Privacy Policy
        </a>
        <a href="mailto:contact@sessionlink.app">
          Contact
        </a>
      </div>
    </>
  );
}

AppContent.propTypes = {
  children: PropTypes.node.isRequired
};
